import React, { useState } from "react"
import { Link } from "gatsby"
import { TrendGraph, PopularityGraph } from "./graphs"
import Loadmore from "./loadmore"
import { getGenderColor, GenderSymbol } from "../components/gender"
import { FavoritesButton } from "../components/favorites"

import styled from 'styled-components'

const Table = styled.table`
    > * > tr > td {
        overflow: hidden;
    }
    > * > tr > :nth-child(1) {
        width: 20px;
    }
    > * > tr > :nth-child(2) {
        width: 150px;
    }
    > * > tr > :nth-child(3) {
        width: auto;
    }
    > * > tr > :nth-child(4) {
        width: 200px;
    }
    > * > tr > :nth-child(5) {
        width: 120px;
    }
    > * > tr > :nth-child(6) {
        width: 60px;
    }
    @media (max-width: 900px) {
        > * > tr > :nth-child(4) {
            width: 150px;
        }
    }
    @media (max-width: 850px) {
        > * > tr > :nth-child(4) {
            display: none;
        }
    }
    @media (max-width: 680px) {
        > * > tr > :nth-child(5) {
            display: none;
        }
    }
    @media (max-width: 520px) {
        > * > tr > :nth-child(3) {
            display: none;
        }
    }
`


export default ({ names, initial_limit, title }) => {
    const [nameLimit, setNameLimit] = useState(initial_limit)

    return (
        <div className="paper" style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
        }}>
            {title && <h2 style={{ alignSelf: "flex-start" }}>{title}</h2>}

            <Table>
                <thead>
                    <tr>
                        <th>{ }</th>
                        <th><h3>Name</h3></th>
                        <th><h3>Herkunft</h3></th>
                        <th><h3>Bedeutung</h3></th>
                        <th><h3>Beliebtheit</h3></th>
                        <th><h3>Trend</h3></th>
                    </tr>
                </thead>
                <tbody>
                    {names.slice(0, nameLimit).map((name) => {

                        const gender_color = getGenderColor(name.gender)
                        return (

                            <tr key={name.id}>
                                <td><FavoritesButton name={name} color={gender_color} size={18} /></td>
                                <td><Link
                                    to={"/" + name.id}
                                    state={{ showBackButton: true }}
                                >
                                    <span style={{ color: gender_color }}><b>{name.name}</b></span></Link> <GenderSymbol gender={name.gender} /></td>
                                <td>{name.origin}</td>
                                <td>{name.meaning}</td>
                                <td>
                                    <PopularityGraph
                                        size={50}
                                        value={name.popularity}
                                        color={gender_color}
                                    />
                                </td>
                                <td>
                                    <TrendGraph births={name.births} color={gender_color}></TrendGraph>
                                </td>
                            </tr>)
                    })}
                </tbody>
            </Table>

            <Loadmore
                state={nameLimit}
                setState={setNameLimit}
                increment={15}
                max={names.length} />
        </div>


    )
}