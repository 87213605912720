import React from "react"
import { Helmet } from "react-helmet"
import Divedowns, { DivedownBox } from "../components/divedowns"
import NamesList from "../components/namesList"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Ad from "../components/ad"

import HeaderPic from "../components/headerpic"
import headerPic from "../../static/headerpics/beliebtheit.png"



export default function Namenslexikon({ data }) {
  const popularity_divedowns = data.pagesJson.divedowns
    .filter((divedown) => (divedown.attribute.category === 'Beliebtheit'))

  const other_divedowns = data.pagesJson.divedowns
    .filter((divedown) => (
      ['Bedeutung', 'Herkunft', 'Drei Anfangsbuchstaben']
        .includes(divedown.attribute.category)
    ))

  const girls = data.allNamesJson.nodes
    .filter(name => name.attributes.map(attr => attr.id).includes('mädchennamen'))

  const boys = data.allNamesJson.nodes
    .filter(name => name.attributes.map(attr => attr.id).includes('jungennamen'))


  return (
    <Layout>
      <Helmet>
        <title>Vornamen nach Beliebtheit</title>
        <meta name="description"
          content="Die beliebtesten, seltensten, trendigsten Jungen- und Mädchennamen." />
        <meta name="keywords"
          content="vornamen, beliebtheit, geburtsstatistiken, top" />
        <link rel="canonical" href="/nach-beliebtheit" />
      </Helmet>
      <div className="paper">
        <HeaderPic imagePath={headerPic} />
        <div className="section">
          <h1>Die beliebtesten Vornamen</h1>
          <p>Die swissmom-Namenssuche verrät Ihnen,
          welche Vornamen in den vergangenen Jahren besonders beliebt waren.
          Hier erfahren Sie, welche Babynahmen es 2016, 2017 und 2018 auf
          der Hitliste ganz nach oben geschafft haben.
              </p>
        </div>
        <DivedownBox divedowns={popularity_divedowns} />
      </div>
      <Ad id="adnz_maxiboard_1" />
      <NamesList names={girls} initial_limit={10} title="Top 10 Mädchennamen" />

      <NamesList names={boys} initial_limit={10} title="Top 10 Jungennamen" />
      <Ad id="adnz_maxiboard_2" />
      <Divedowns divedowns={other_divedowns} />
      <Ad id="adnz_maxiboard_3" />
    </Layout>
  )
}

export const query = graphql`
  query {
    pagesJson(url: {eq: ""}) {
      divedowns {
        attribute {
          imagePath
            associated_page_url
            category
            id
            latest_births
            popularity
            rank
            title
            trend
            births {
              births
              years
            }
        }
        number_of_names
        subpage_url
      }
    }
    allNamesJson(
        sort: {order: DESC, fields: popularity},
        limit: 200,
        ) {
        nodes {
            attributes {
          imagePath
                id
            }
            gender
            id
            meaning
            name
            origin
            popularity
            births {
                births
                years
            }
        }
    }
  }
  `